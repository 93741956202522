import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import api from "services/api";
import {removeAccentuation} from "helpers/functions";
import database from 'assets/database.png'

export default function EditorModal({instance, show, onHide}) {
	const [instanceName, SetInstanceName] = useState('');
	const [databaseName, SetDatabaseName] = useState('');
	const [serverName, SetServerName] = useState('');
	const [port, SetPort] = useState('');
	const [username, SetUsername] = useState('');
	const [password, SetPassword] = useState('');
	const [brand, SetBrand] = useState('');
	const [guid, SetGuid] = useState('');
	const [active, SetActive] = useState(1);

	const [databasesInUse, setDatabasesInUse] = useState([]);
	const [runningProcess, setRunningProcess] = useState(false);
	const [minDbVersionId, setMinDbVersionId] = useState(0);
	const [gettingDatabases, setGettingDatabases] = useState(false);
	const [debounceTimeout, setDebounceTimeout] = useState(null);
	const [isFormValid, setIsFormValid] = useState(false);
	const [alertMessage, setAlertMessage] = useState(true);
	const [message, setMessage] = useState({message: '', type: ''});

	async function getListOfDatabases() {
		if (isFormValid) {
			setGettingDatabases(true);
			try {
				const {data} = await api.get('/settings/instances/testConnection?databasesWithVersions=1', {
					host: serverName,
					port: port,
					username: username,
					password: password,
				});
				setMinDbVersionId(data.minDbVersionId);
				setDatabasesInUse(data.databases);
			} catch (error) {
				setAlertMessage(true);
				setMessage({
					message: `Erro ao tentar conectar (${error})`,
					type: 'danger',
				});
			} finally {
				setGettingDatabases(false);
			}
		}
	}

	async function update(e) {
		e.preventDefault();

		setRunningProcess(true);

		try {
			await api.post('/settings/instances/update', {
				instance: instance.GUID,
				name: instanceName,
				server: serverName,
				port: port,
				username: username,
				password: password,
				database: databaseName,
				active: active,
			});

			setAlertMessage(true)
			setMessage({message: 'Instância Atualizada com Sucesso!', type: 'success'})
		} catch (err) {
			if (err.response) {
				setAlertMessage(true)
				setMessage({
					message: `Ocorreu um erro ao tentar atualizar a instância: (${err.response.data})!`,
					type: 'danger'
				})
			}
		} finally {
			setRunningProcess(false);
		}
	}

	useEffect(() => {
		if (debounceTimeout) {
			clearTimeout(debounceTimeout);
		}

		const newTimeout = setTimeout(() => {
			getListOfDatabases();
		}, 600);

		setDebounceTimeout(newTimeout);

		return () => clearTimeout(newTimeout);
	}, [username, serverName, port, password]);


	async function getInstance() {
		try {
			const {data} = await api.get('/settings/instances/one',
				{instance: instance.GUID}
			);
			SetPassword(data.decryptedPassword);

			setIsFormValid(true);
			SetInstanceName(data.instanceData.Name || '');
			SetUsername(data.instanceData.Username || '');
			SetServerName(data.instanceData.Server || '');
			SetPort(data.instanceData.Port || '');
			SetDatabaseName(data.instanceData.Db || '');
			SetBrand(data.instanceData.Brand || '');
			SetGuid(data.instanceData.GUID || '');
			SetActive(data.instanceData.Active);

			if (isFormValid) {
				getListOfDatabases();
			}

		} catch (err) {
			console.error(err);
		}
	}

//	async function setImageInstance(e, Instance) {
//		let image = e.currentTarget.files[0];
//		const data = new FormData();
//		data.append('instance', Instance.GUID);
//		data.append('image', image, image.filename);
//
//		if (image) {
//			try {
//				const response = await api.instance().post('/settings/instances/image', data, {headers: {'Content-Type': 'multipart/form-data'}});
//				SetBrand(response.data.filename)
//
//				setAlertMessage(true);
//				setMessage({message: 'Imagem Atualizada com Sucesso!', type: 'success'});
//			} catch (err) {
//				if (err.response) {
//					setAlertMessage(true);
//					setMessage({message: `Erro ao atualizar a imagem: (${err.response.data})`, type: 'danger'});
//				}
//			}
//		}
//	}

	useEffect(() => {
		getInstance()
		return () => api.cancelAllRequests();
	}, [instance]);

	const handleModalHide = () => {
		api.cancelAllRequests();
		onHide();
		setAlertMessage(false);
	};

	return (
		<Modal
			show={show}
			onHide={handleModalHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className={'border-0'}>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={update}>
					{alertMessage ? <Alert variant={message.type}>{message.message}</Alert> : null}
					
					{/*<div className="text-center mb-2 picture">*/}
					{/*	<img*/}
					{/*		onClick={e => {*/}
					{/*			e.preventDefault();*/}
					{/*			window.$(`input[name="instance-${guid}-image"]`).trigger('click');*/}
					{/*		}}*/}
					{/*		src={brand ? `${process.env.REACT_APP_API_STORAGE_BASEURL}/instances/brands/${brand}` : database}*/}
					{/*		height="94"*/}
					{/*		alt=""*/}
					{/*	/>*/}
					{/*	<div>*/}
					{/*		<input*/}
					{/*			className="d-none"*/}
					{/*			accept="image/png"*/}
					{/*			type="file"*/}
					{/*			name={`instance-${guid}-image`}*/}
					{/*			onChange={e => setImageInstance(e, instance)}*/}
					{/*		/>*/}
					{/*	</div>*/}
					{/*</div>*/}


					<Row>
						<Col>
							<Form.Label column={true}>Nome da Instância</Form.Label>
							<Form.Control
								type="text"
								value={instanceName}
								className="text-start border-1 shadow-none"
								style={{fontSize: 14}}
								autoComplete="off"
								placeholder="Ex: ExemploDB, DBExemplo, ExemploInstance"
								onChange={e => {
									let b = e.currentTarget.value;
									SetInstanceName(b);
								}}
							/>
						</Col>
						<Col lg={60} className="mr-2">
							<Form.Group className="mb-3" controlId="controlStatus">
								<Form.Label column={true}>Status</Form.Label>
								<div className="d-flex justify-content-start">
									<Button
										variant={active === 1 ? 'success' : 'outline-success'}
										className="shadow-none mr-2"
										onClick={() => SetActive(1)}
									>
										Ativo
									</Button>
									<Button
										variant={active === 0 ? 'danger' : 'outline-danger'}
										className="shadow-none"
										onClick={() => SetActive(0)}
									>
										Inativo
									</Button>
								</div>
							</Form.Group>
						</Col>

						<Col xs="12">
							<Row className="justify-content-around mb-3">
								<Col>
									<Form.Label column={true}>Username</Form.Label>
									<Form.Control
										type="text"
										style={{fontSize: 14}}
										autoComplete="off"
										placeholder="Ex: sa, master, root"
										className="text-start border-1 shadow-none"
										value={username}
										onChange={e => SetUsername(removeAccentuation(e.currentTarget.value).replace(/([^A-Za-z]*)/g, ''))}
									/>
								</Col>
								<Col>
									<Form.Label column={true}>Servidor</Form.Label>
									<Form.Control
										type="text"
										style={{fontSize: 14}}
										autoComplete="off"
										placeholder="Ex: sa.exemplo.com, @master.host.com, exemplo.com"
										className="text-start border-1 shadow-none"
										value={serverName}
										onChange={e => SetServerName(e.currentTarget.value)}
									/>
								</Col>
								<Col>
									<Form.Label column={true}>Porta</Form.Label>
									<Form.Control
										type="number"
										style={{fontSize: 14}}
										autoComplete="off"
										placeholder="Ex: 1442, 8883, 90"
										className="text-start border-1 shadow-none"
										value={port}
										onChange={e => SetPort(e.currentTarget.value)}
									/>
								</Col>
								<Col>
									<Form.Label column={true}>Senha</Form.Label>
									<Form.Control
										type="password"
										style={{fontSize: 14}}
										autoComplete="off"
										placeholder=""
										className="text-start border-1 shadow-none"
										value={password}
										onChange={e => SetPassword(e.currentTarget.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-around mb-3">
								<Col>
									<Form.Label column={true}>Banco de Dados</Form.Label>
									{gettingDatabases ? (
										<span className="align-content-center">
																<Spinner size="sm" variant="primary" animation="border"
																				 className="mr-1"/> Buscando dados...
															</span>
									) : (
										<Form.Control
											disabled={gettingDatabases}
											className="border-bottom-1 text-start shadow-none"
											as="select"
											style={{WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none'}}
											onChange={e => SetDatabaseName(e.target.value)}
										>
											<option value={databaseName}>
												Atual: {databaseName}
											</option>
											{databasesInUse.map((db, idx) => (
												<option key={idx} disabled={minDbVersionId >= db.version.id} value={db.name}>
													{db.name}{minDbVersionId >= db.version.id && (' (Versão Incompatível)')}
												</option>
											))}
										</Form.Control>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
					{!runningProcess ? (
						<div className="text-center mt-4 display-1 justify-content-around w-100">
							<Button
								variant={'danger'}
								className={`shadow-none mr-3`}
								onClick={handleModalHide}
							>
								Cancelar
							</Button>
							<Button
								variant={'success'}
								className={`shadow-none`}
								type="submit"
								disabled={[instanceName, databaseName].indexOf('') > -1}
							>
								Salvar
							</Button>
						</div>
					) : (
						<div className="d-flex justify-content-center mt-4">
							<div className="spinner-border text-success" role="status"></div>
						</div>
					)}
				</Form>
			</Modal.Body>
		</Modal>
	);
}