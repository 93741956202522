import React, {useEffect, useState} from 'react'

import {Row, Col, Form, Button} from 'react-bootstrap'
import api from 'services/api';

export default function StepUser({setStep, data}) {

	const {instance} = data;

	const [selectExistingUser, setSelectExistingUser] = useState(false)
	const [usersExisting, setUsersExisting] = useState(null);
	const [requestPending, setRequestPending] = useState(false);
	const [responseErrMessage, serResponseErrMessage] = useState('');
	const [userInstanceExists, setUserInstanceExists] = useState(false);
	const [usersInstance, setUsersInstance] = useState(null);
	// const [sendEmail, setSendEmail]                     = useState(false);
	const [userVerified, setUserVerified] = useState(true);

	async function onSubmitRegisterUser(e) {
		e.preventDefault();
		let user = {}
		window.$(e.currentTarget).serializeArray().forEach(element => {
			user[element.name] = element.value;
		});

		if (!selectExistingUser) {
			if (Object.values(user).findIndex(e => e === '') !== -1) {
				serResponseErrMessage('Preencha todos os campos');
				return;
			}
		}

		serResponseErrMessage('');
		setRequestPending(true);
		try {
			await api.post('/settings/users', {
				instance: instance,
				user: user,
				userVerified: userVerified ? 1 : 0,
				existing: 1,
				userByInstance: 1,
				// sendEmail:      sendEmail ? 1 : 0
			});

			setStep({
				current: 'Finalized',
				data: {}
			});
			return;
		} catch (err) {
			if (err.response) {
				let sttmsg = {
					FieldsMissing: 'Olha, tem alguns campos faltando...',
					InstanceInvalid: 'Identificação da instância comprometida. Não é possível continuar',
					UserAlreadyRegistered: 'Usuário já cadastrado. Pode ser o e-mail, usuário e/ou telefone...',
					UserNotRegistered: 'Não foi possível registrar usuário. Deu ruim!',
					UserInstanceAlreadyLinked: 'Já existe um vínculo para o usuário da instância!',
					UserAlreadyLinkedInstance: 'Já existe um vinculo para este usuário na instância ' + instance.name + ', não é possível duplicá-lo.'
				}
				serResponseErrMessage(sttmsg[err.response.data.status]);
			}
		}
		setRequestPending(false);
	}

	async function getUsers() {
		const {data} = await api.get('/settings/users?noPagination=1');
		setUsersExisting(data);
	}

	useEffect(() => {
		if (selectExistingUser) {
			if (usersExisting === null) {
				serResponseErrMessage('');
				getUsers();
			}
		} else {
			serResponseErrMessage('');
			initFormRegister();
		}
		// eslint-disable-next-line
	}, [selectExistingUser]);

	useEffect(() => {
		if (userInstanceExists === true && usersInstance === null) {
			api.get('/settings/instances/look/users', {instance: instance.id})
				.then(({data}) => {
					// Ensure the data is an array before setting it
					setUsersInstance(Array.isArray(data) ? data : []);
					setTimeout(() => {
						window.$('#frmUser-users-instance').select2();
					}, 10);
				});

		}
		if (userInstanceExists) {
			setTimeout(() => {
				window.$('#frmUser-users-instance').select2();
			}, 10);
		}
		// eslint-disable-next-line
	}, [userInstanceExists])

	useEffect(() => {
		if (usersExisting !== null) {
			window.$('#frmUser-users-existing').select2({
				templateSelection: (state) => {
					let data = state.text.split('|');
					return window.$(`<span>${data[0]}</span>`);
				},
				placeholder: 'Selecionar Usuário',
				templateResult: (state) => {
					let data = state.text.split('|');
					return window.$(`<div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex">
                            <div class="rounded-circle shadow-sm bg-white" style="width:42px;height:42px;text-align:center;line-height:42px;color:#333;">${(data[0]).substr(0, 2).toUpperCase()}</div>
                            <h5 class="ml-2">${(data[0])}<div>
                                <small>${data[2]}</small>
                                <small>${data[3]}</small>
                            </div></h5>
                        </div>
                        <div>
                            <span class="badge font-10 badge-${parseInt(data[4]) ? 'success' : 'danger'} py-1 px-2 rounded-pill">${parseInt(data[4]) ? 'Verificado' : 'Não Verificado'}</span>
                        </div>
                    </div>`);
				}
			});
		}
	}, [usersExisting, selectExistingUser]);

	function initFormRegister() {
		window.$.getJSON(process.env.PUBLIC_URL + '/assets/data/countries.json', countries => {
			window.$("#frmUser-countrycode").select2({
				data: countries.map(item => ({id: Number(item.phone), text: item.name, iso: item.iso})),
				minimumInputLength: 2,
				placeholder: 'Selecione o país',
				templateResult: (state) => {
					let $state = window.$(`<span>${state.iso ? '<img src="https://lipis.github.io/flag-icon-css/flags/4x3/' + state.iso.toLowerCase() + '.svg" alt="" width="24" /> ' : ''} +${Number(state.id)} ${state.text}</span>`);
					return $state;
				}
			});
		});
		window.$('[data-data-toggle="input-mask-cellphone"]').mask("00000000000");

	}

	return (
		<div className="user">
			<Form onSubmit={onSubmitRegisterUser} className="my-4">
				<Row className="justify-content-center">
					<Col md="5">
						{selectExistingUser ? (
							<div>
								{usersExisting !== null ?
									usersExisting.length === 0
										? (
											<div className="my-4">Nenhum usuário encontrado</div>
										) : (
											<div>
												<h4>Usuário</h4>
												<Form.Group controlId="frmUser-users-existing">
													<Form.Control name="user" as="select">{
														usersExisting.map((User, idx) => (
															<option value={User.Email}
																			key={idx}>{User.Name}|{User.Email}|{User.Username}|{User.Phone}|{User.Verified}</option>
														))
													}</Form.Control>
												</Form.Group>
											</div>
										)
									: (
										<div className="d-flex justify-content-center my-3">
											<div className="spinner-border" role="status"></div>
										</div>
									)}
							</div>
						) : (
							<div>
								<h4>Novo Usuário</h4>
								<Form.Group controlId="frmUser-name">
									<Form.Control name="name" autoComplete="off" type="text" placeholder="Nome Completo"/>
								</Form.Group>

								<Form.Group controlId="frmUser-username">
									<Form.Control name="username" autoComplete="off" type="text" placeholder="Usuário"/>
								</Form.Group>

								<Form.Group controlId="frmUser-email">
									<Form.Control name="email" autoComplete="off" type="email" placeholder="E-mail"/>
								</Form.Group>

								<Form.Group controlId="frmUser-password">
									<Form.Control name="password" autoComplete="off" type="password" placeholder="Senha"/>
								</Form.Group>

								<div className="mt-3">
									<Row className="mt-2">
										<Col>
											<Form.Group controlId="frmUser-countrycode">
												<Form.Control name="contact.countrycode" as="select">
													<option value="55" selected>Brasil</option>
												</Form.Control>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group controlId="frmUser-cellphone">
												<Form.Control name="contact.cellphone" autoComplete="off" type="text"
																			data-data-toggle="input-mask-cellphone" placeholder="DDD + Número"/>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</div>
						)}

						<div className="mt-3">
							<Button onClick={() => setSelectExistingUser(!selectExistingUser)} variant="link"
											className="p-0">{selectExistingUser ? 'Cadastrar Novo Usuário' : 'Selecionar Usuário Existente'}</Button>
						</div>

					</Col>
					<Col md="5">
						<div>
							<h4>Opções</h4>
							{!selectExistingUser && (
								<div className="mb-3">
									<div className="d-flex justify-content-between align-items-center">
										<span>Verificado</span>
										<div>
											<input type="checkbox" id="kjashdbu2b987" onChange={e => setUserVerified(e.currentTarget.checked)}
														 checked={userVerified} data-switch="success"/>
											<label htmlFor="kjashdbu2b987" data-on-label="Sim" data-off-label="Não"></label>
										</div>
									</div>
									<p className="font-11 text-muted">Sem necessidade de confirmar a conta por e-mail/sms.</p>
								</div>
							)}
							<div className="d-flex justify-content-between align-items-center">
								<span>Vincular Usuário Instância</span>
								<div>
									<input type="checkbox" id="hd8724xdbdinuo"
												 onChange={e => setUserInstanceExists(e.currentTarget.checked)} checked={userInstanceExists}
												 data-switch="success"/>
									<label htmlFor="hd8724xdbdinuo" data-on-label="Sim" data-off-label="Não"></label>
								</div>
							</div>
							<p className="font-11 text-muted">Selecionar um usuário existente no banco de dados {instance.name} para
								vincular.</p>
							{userInstanceExists && (
								<div>
									{usersInstance !== null ? (
										<Form.Group controlId="frmUser-users-instance">
											<Form.Control name="user.instance" as="select">{
												usersInstance.map((User, idx) => (
													<option value={User.ID_Usuario}
																	key={idx}>{User.ID_Usuario} {User.Nome || ''} - {User.NomeCompleto}</option>
												))
											}</Form.Control>
										</Form.Group>
									) : (
										<div className="spinner-border" role="status"></div>
									)}
								</div>
							)}
							{/* <div className="d-flex justify-content-between align-items-center mt-3">
                                <span>Enviar E-mail</span>
                                <div>
                                    <input type="checkbox" id="hunxbye9gb928g" onChange={e => setSendEmail(e.currentTarget.checked)} checked={sendEmail} data-switch="success" />
                                    <label htmlFor="hunxbye9gb928g" data-on-label="Sim" data-off-label="Não"></label>
                                </div>
                            </div>
                            <p className="font-11 text-muted">Um e-mail será enviado com dados os dados de acesso.</p> */}
						</div>
					</Col>

					<Col xs="12">
						<div className="mt-4 text-center">
							{responseErrMessage !== '' && (
								<p className="text-danger">{responseErrMessage}</p>
							)}
							<Button variant="primary" disabled={requestPending} className="shadow-none mt-4" type="submit">{
								!requestPending ? 'Cadastrar'
									: <div className="spinner-border" role="status"></div>
							}</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	);
}